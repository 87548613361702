@font-face {
  font-family: Yeezy;
  src: url("/public/fonts/yeezy_tstar-bold-webfont.ttf");
}
/* langauge change link */
.link {
  cursor: pointer;
  font-size: 1em;
  letter-spacing: 5px;
  color: gray;
  text-decoration: none;
  border: none;
  background-color: transparent;
}
.link:hover {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
}
.link.active {
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
}

/* general */
.unclickable {
  pointer-events: none;
}
.shadowed {
  filter: drop-shadow(0px 0px 4px rgb(99, 98, 98));
}
:root {
  --auto-scroll-max-width: 400px;
  --gradient-color: linear-gradient(
          to top,
          rgb(245, 173, 125) 0%,
          rgb(255, 246, 231) 50%,
          rgb(245, 173, 125) 80%
  );
}
.g-c-text {
  background: var(--gradient-color);
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.df {
  display: flex;
}
.align-fs{
  align-items: flex-start;
}
.w100 {
  width: 100%;
}
main > div {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 0;
  box-shadow: none;
  position: relative;
  opacity: 0;
  animation: opaque 250ms linear forwards;
  animation-iteration-count: 1;
}

@keyframes colorr {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}
@keyframes anim {
  0% {
    filter: blur(0px);
    color: white;
  }

  100% {
    filter: blur(10px);
    color: transparent;
  }
}
@keyframes zoomin {
  from {
    scale: 1;
  }
  to {
    scale: 2.5;
    filter: opacity(0);
  }
}
@keyframes scalex{
  to{
    width: 400px;
  }
}
@keyframes opaque {
  to {
    opacity: 1;
  }
}
@keyframes appear {
  to {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    opacity: 1;
  }
}
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-420px);
  }
}

#name {
  animation: colorr 5s linear infinite;
  pointer-events: none;
  background-size: 200% 200%;
  letter-spacing: 5px;
  filter: drop-shadow(9px 10px 4px black);
}
#degree {
  position: relative;
}
#degree #degree-img {
  position: absolute;
  width: 500px;
  height: 350px;
  top: 0;
  transition: 500ms ease-in-out;
  transform: translateX(100%);
  scale: 0;
  object-fit: cover;
  filter: drop-shadow(1px 1px 10px black);
}
.pfp {
  animation: zoomin linear forwards;
  -webkit-animation: zoomin linear forwards;
  height: 100%;
  position: absolute;
  top: 0;

  z-index: -1;
  background-color: #464646;
}
.pfp img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: drop-shadow(19px 10px 4px black);
  pointer-events: none;
}
.social-links{
  gap: 10px;
  align-items: center;
  padding: 10px;
}
/*console*/
.console{
  background: #9f9f9f;
  border-radius: 0 5px 5px 0;
  color: #0a090c;
  max-width: 95%;
  height: 200px;
}
.console p{
  background-color: black;
  padding: 15px 5px;
  font-family: monospace;
  font-size: 1.2em;
  color: #2bff2b;
  height: 100%;
  animation: scalex 500ms forwards;
  max-width: 100%;
}

.skill-section {
  width: 50%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.skill-section,
.experience-item,
.projects {
  animation: opaque 250ms linear forwards;
  animation-timeline: view(90% 10%);
}

.skillitemist {
  gap: 10px;
  justify-content: center;
  max-width: var(--auto-scroll-max-width);
  overflow: hidden;
  box-sizing: content-box;
  padding: 15px;
  border-radius: 10px;
}

.skillitemist.scrollable {
  justify-content: flex-start;
  pointer-events: none;
}
.skills-animoji {
  position: absolute;
  width: 300px;
  left: 0;
  transform: translateX(-300px);
  animation: appear 250ms linear forwards;
  animation-timeline: view(70% 20%);
}

.skillitemist.scrollable li {
  box-sizing: content-box;
  animation: scroll linear 5s infinite;
}
.skillitemist li {
  max-width: 60px;
  min-width: 60px;
  z-index: 1;
}
.skill-icons {
  object-fit: cover;
}
.experience-item img {
  width: 40%;
  max-width: 200px;
  object-fit: cover;
}

.experience-item ul li {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.load-wrapper {
  position: absolute;
  z-index: 3;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 500ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: visible;
}
.load-wrapper.off {
  visibility: hidden;
  z-index: -100;
  opacity: 0;
}
footer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  justify-content: center;
  gap: 10px;
}


/*PROJECTS ITEM*/
.active-item {
  position: absolute;
  transition: 0.5s ease-in-out;
  left: 20px;
  flex-direction: column;
  align-items: flex-start;
  top: 50%;
  transform: translateY(-50%);
  width: 50%;

}
.projects {
  gap: 15px;
  pointer-events: none;
}
.project-list {
  position: relative;
  width: 80%;
  align-items: flex-end ;
  box-sizing: content-box;
  gap: 5px;
  pointer-events: none;
  outline: 1px solid rgb(245, 173, 125);
  height: 800px;
  border-radius: 10px;
  justify-content: flex-end;

}
#learn-more-btn {
  pointer-events:all;
  cursor: pointer;
  margin-top: 10px;
  font-size: 1em;
}
.project-status{
  font-size: 0.15em;
  background-color: #1db51d;
  padding: 0 5px;
  border-radius: 5px;
  vertical-align: top;
  box-shadow: 0 0 3px 1px #2bff2b;
}
#active-img {
  position: absolute;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  transition: 0.5s ease-in-out;
}
.project-list > section {
  width: 50%;
  max-width: 650px;
  cursor: pointer;
  pointer-events: all;
  border-radius: 10px;
  transition: 150ms ease-in-out;
  margin-bottom: 90px;
  box-shadow: 0 0 0 2px #757575;
  padding: 5px;
  overflow-x: auto;
  height: 120px;
  gap: 10px;
}
.project-list > section> figure{
  width: 150px;
  min-width: 150px;
  pointer-events: all;
  height: 90px;
  border-radius: 10px;

}
.project-list > section > figure:hover {
  outline: 2px solid rgb(245, 173, 125);
  translate: 0 -1px;
}

.project-preview {
  align-items: center;
  flex-direction: row;
  height: 100%;
  position: relative;
  margin: 0;
}

.project-preview figcaption {
  width: 50%;
  height: 100%;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 250ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.449);
  backdrop-filter: blur(5px);
  display: none;
}

.project-preview figcaption p {
  text-align: center;
  letter-spacing: 1px;
}

.project-image {
  height: 100%;
  position: absolute;
  z-index: -1;
}
.project-image img {
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 768px) {
  main > div {
    width: 100%;
    height: 100%;
  }
  .logo-img {
    max-width: 50px;
  }

  #name {
    font-size: 4em;
  }

  .skills-animoji {
    left: unset;
    width: 100px;
  }
  .pfp {
    animation: unset;
    height: 50%;
    position: absolute;
  }

  .project-list > li {
    height: 115px;
    width: 100%;
    outline: 1px solid #f5ad7d;
  }
  .project-preview {
    gap: 0;
  }
  .project-image {
    width: 50%;
    position: relative;
  }

  .project-preview figcaption {
    width: 50%;
    opacity: 1;
    display: flex;
  }
  .project-preview figcaption p {
    font-size: 0.7em;
  }
  .projects {
    height: fit-content;
    display: none;
  }
  /*for projects view animation*/
  .active-item,
  #active-img {
    display: none;
  }
  .project-list {
    align-items: center;
    flex-direction: column;
    gap: 5px;
    max-width: 1000px;
    padding: 5px;
    height: 700px;
    justify-content: flex-start;
    outline: none;
  }
}

.p-btn{
  border: none;
  border-radius: 10px;
  background: var(--gradient-color);
  background-clip: text;
  color: transparent;
  padding: 10px;
  font-size: 1.2em;
  box-shadow: 0 0 5px wheat;
  transition: 250ms ease-in-out;
}
button:not([disabled]):hover{
  cursor: pointer;
}
.p-btn:hover {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.54);
}
/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
  width: 2px;
  background-color: transparent;
}


::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--gradient-color);
}
