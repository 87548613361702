@font-face {
  font-family: Yeezy;
  src: url("/public/fonts/yeezy_tstar-bold-webfont.ttf");
}
* {
  box-sizing: border-box;
}
body {  
  color: #e6e4f7;
  margin: 0;  
  background-color: #0a090c;
  overflow: hidden;
  font-family: "Yeezy";
}

#root {

}
header {
  position: absolute;
  width: 90%;
  justify-content: flex-end;
  padding: 5px;
  height: 60px;
  z-index: 2;
  gap: 10px;
  align-items: center;
}

p {
  font-family: "Yeezy";
  letter-spacing: 1.5px;
  margin-top: 0;
  margin-bottom: 1rem;
}
li {
  list-style: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2 {
  font-family: "Yeezy";
  text-align: center;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 9em;
}
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-left: 2rem;
}
/* main content*/
main {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: clip;
  overflow-y: scroll;
}