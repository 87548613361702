.chatbox-toggle {
  position: fixed;
  bottom: 30px;
  right: 10px;
  z-index: 2;
}
.chatbox-toggle:hover {
  box-shadow: 0 5px 5px rgb(245, 173, 125);
}
.chat {
  position: fixed;
  bottom: 10px;
  width: 95%;
  z-index: 2;
  max-width: 400px;
  right: 10px;
  backdrop-filter: blur(20px);
  opacity: 1;
  transition: 350ms ease-in;
 /* background: linear-gradient(
          to bottom,
          rgb(245, 173, 125) 0%,
          rgb(33, 33, 33) 10%,
          transparent 15%);*/
  border-radius: 5px;
  height: 50dvh;
  min-height: 620px;
  box-shadow: 0 0 1px 3px rgb(245, 173, 125);
}

.chat.closed {
  transform: translateX(105%);
  opacity: 0;
}

.chat-close {
  background: transparent;
  background-clip: border-box;
  border: none;
}
.chat-close:hover {
  background: transparent;
  border: none;
}

.chat-header {
  display: flex;
  place-content: space-between;
  place-items: center;
  height: 50px;
  padding: 10px;
  box-sizing: content-box;
 
}

.chat-logo {
  width: 50px;
  height: 50px;
  filter: drop-shadow(2px 2px 2px rgba(255, 11, 11, 0.5));
  animation: color-bounce 5s infinite;
}
.chat-body{
  height: 100%;
}


@keyframes color-bounce {
  0% {
    filter: hue-rotate(0deg);
  }

  50% {
    filter: hue-rotate(180deg);
  }

  100% {
    filter: hue-rotate(360deg);
  }
}

#messages {
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: 450px;

}

.msg {
  color: transparent;
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );
  background-clip: text;
  text-align: right;

  width: 100%;

  animation: msgAppear 350ms ease-in;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.msg.i{
  justify-content: flex-end;
}

.msg p {
  text-align: left;
  width: 70%;


}

@keyframes msgAppear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.msg.i p {
  text-align: right;
}

#chat-form {
  display: none;
  height: 50px;
}

#hidden-chat-form {
  display: flex;
  justify-content: center;
  box-shadow: 0 -5px 5px 1px rgb(245, 173, 125);

}

.form-control {
  width: 85%;
  border: 2px solid transparent;
  font-size: 1rem;
  padding: 15px;
  border-radius: 20px;
  background-image: linear-gradient(wheat, grey),
    linear-gradient(
      to right,
      rgb(255, 111, 15) 0%,
      rgb(255, 23, 23) 10%,
      rgb(255, 203, 61) 20%,
      rgb(131, 255, 15) 30%,
      rgb(15, 255, 203) 40%,
      rgb(15, 131, 255) 50%,
      rgb(203, 61, 255) 60%,
      rgb(255, 23, 23) 70%,
      rgb(255, 111, 15) 80%,
      rgb(255, 203, 61) 90%,
      rgb(131, 255, 15) 100%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  background-size: 200% 200%;
  font-family: Yeezy;
  color: black;
  animation: bkImg 10s infinite;
  transition: 500ms ease-in;
  flex-grow: 2;
}
.form-control:focus{
  outline: none;
  box-shadow: 0 0 5px 5px rgba(0, 123, 255, 0.25);
}
#email-input.loading {
  width: 50px;
  animation: bkImg 3s infinite;
}

@keyframes bkImg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

#chat-form input:focus {
  outline: 1px solid yellow;
}

#send-btn,
#set-chat-btn {
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );
  background-clip: text;
  border: none;
  color: transparent;
  font-size: 1.2em;
  cursor: pointer;
  flex-grow: 1;
  font-family: Yeezy;
}

.blink-underscore{
  animation: blink 1s infinite;
  color: transparent;
  background: linear-gradient(
    to right,
    rgb(245, 173, 125) 0%,
    rgb(255, 246, 231) 50%,
    rgb(245, 173, 125) 80%
  );
  background-clip: text;
  
  overflow: hidden;
  transition: 500ms ease-in;
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }  
}
@media (max-width: 768px) {
  .chatbox-toggle{
    display: none;
  }
}
